import { datadogRum } from '@datadog/browser-rum';

export const syncFeatureFlagsWithRUM = (context, flags) => {
  datadogRum?.addRumGlobalContext?.(context, flags);
};

export const addFeatureFlagEvaluation = (flags) => {
  if (datadogRum?.addFeatureFlagEvaluation) {
    Object.keys(flags).forEach((flagKey) => {
      try {
        datadogRum.addFeatureFlagEvaluation(flagKey, flags[flagKey]);
      } catch (error) {
        console.error(`Error adding flag "${flagKey}" evaluation to Datadog:`, error);
      }
    });
  } else {
    console.warn('Datadog RUM is not initialized or addFeatureFlagEvaluation is unavailable');
  }
};
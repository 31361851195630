import { TRACK_EVENTS } from 'constants/trackEvents';

export const PENDO_TRACK_EVENTS = {
  // Spaces AI events
  [TRACK_EVENTS.SPACES_AI_REPHRASE]: 'spacesAI_rephrase',
  [TRACK_EVENTS.SPACES_AI_TICKET_SUMMARY_AND_SENTIMENT_DISPLAYED]: 'spacesAI_ticket_summary_and_sentiment_displayed',
  [TRACK_EVENTS.SPACES_AI_TICKET_SUMMARY_ERROR]: 'spacesAI_ticket_summary_error',
  [TRACK_EVENTS.SPACES_AI_SUGGESTED_CATEGORY_CHECKED]: 'spacesAI_suggested_category_checked',
  [TRACK_EVENTS.SPACES_AI_SUGGESTED_CATEGORY_SELECTED]: 'spacesAI_suggested_category_selected',
  
  // Custom columns events
  [TRACK_EVENTS.CUSTOM_COLUMN_ADD_TO_TEMPLATE]: 'CUSTOM_COLUMN_ADD_TO_TEMPLATE',
  [TRACK_EVENTS.CUSTOM_COLUMN_REMOVE_FROM_TEMPLATE]: 'CUSTOM_COLUMN_REMOVE_FROM_TEMPLATE',
  [TRACK_EVENTS.CUSTOM_COLUMN_POPULATED]: 'CUSTOM_COLUMN_POPULATED',
  
  // Activities events
  [TRACK_EVENTS.ACTIVITIES_CLICKED]: 'ACTIVITIES_CLICKED',
  [TRACK_EVENTS.ACTIVITY_SUBMITTED]: 'ACTIVITY_SUBMITTED',
  [TRACK_EVENTS.ACTIVITY_DELETED]: 'ACTIVITY_DELETED',
  
  // Ticket actions
  [TRACK_EVENTS.TICKET_MISSING_REQUIRED_FIELDS]: 'TICKET_MISSING_REQUIRED_FIELDS',
  [TRACK_EVENTS.SWITCH_TO_CLASSIC_FEEDBACK]: 'SWITCH_TO_CLASSIC_FEEDBACK',
  [TRACK_EVENTS.ADD_NEW_COLUMN]: 'ADD_NEW_COLUMN',
  [TRACK_EVENTS.DELETE_COLUMN]: 'DELETE_COLUMN',
  [TRACK_EVENTS.OPEN_SR_FROM_QUEUE]: 'Open_SR_from_queue',
  [TRACK_EVENTS.DELETE_TICKET_WAS_CLICKED]: 'DELETE_TICKT_WAS_CLICKED',
  
  // Template actions
  [TRACK_EVENTS.SET_TEMPLATE_AS_DEFAULT_WAS_CLICKED]: 'SET_TEMPLATE_AS_DEFAULT_WAS_CLICKED',
  [TRACK_EVENTS.DUPLICATE_TEMPLATE_WAS_CLICKED]: 'DOPLICATE_TEMPLATE_WAS_CLICKED',
  [TRACK_EVENTS.DELETE_TEMPLATE_WAS_CLICKED]: 'DELETE_TEMPLATE_WAS_CLICKED',
  
  // UI interactions
  [TRACK_EVENTS.POPUP_LINK_CLICKED]: 'POPUP_LINK_CLIKED',
  [TRACK_EVENTS.POPUP_BUTTON_CLICKED]: 'POPUP_BUTTON_CLICKED',
  [TRACK_EVENTS.OPEN_CLASSIC_QUEUE_CLICK]: 'open_classic_queue_click',
  [TRACK_EVENTS.OPEN_IN_CLASSIC_FROM_QUEUE_CLICK]: 'open_in_classic_from_queue_click',
  [TRACK_EVENTS.OPEN_IN_CLASSIC_FROM_TICKET_CLICK]: 'open_in_classic_from_ticket_click',
  [TRACK_EVENTS.RESOURCE_CENTER_MENU_CLICK]: 'Resource_center_menu_click',
  
  // Conversion events
  [TRACK_EVENTS.CONVERT_TO_REQUEST_QUEUE]: 'Convert_to_request_queue',
  [TRACK_EVENTS.CONVERT_TO_REQUEST_TICKET_PAGE]: 'Convert_to_request_ticket_page',
  [TRACK_EVENTS.CONVERT_TO_INCIDENT_QUEUE]: 'Convert_to_incident_queue',
  [TRACK_EVENTS.CONVERT_TO_INCIDENT_TICKET_PAGE]: 'Convert_to_incident_ticket_page',
  [TRACK_EVENTS.GO_BACK_BUTTON_CONVERSION_POPUP_CONVERT_TO_REQUEST]: 'Go_back_button_conversion_popup_convert_to_request',
  [TRACK_EVENTS.CONVERT_BUTTON_CONVERSION_POPUP_CONVERT_TO_REQUEST]: 'Convert_button_conversion_popup_convert_to_request',
  [TRACK_EVENTS.GO_BACK_BUTTON_CONVERSION_POPUP_CONVERT_TO_INCIDENT]: 'Go_back_button_conversion_popup_convert_to_incident',
  [TRACK_EVENTS.CONVERT_BUTTON_CONVERSION_POPUP_CONVERT_TO_INCIDENT]: 'Convert_button_conversion_popup_convert_to_incident',
  [TRACK_EVENTS.CONVERSION_SUCCEEDED]: 'Conversion succeeded',
  [TRACK_EVENTS.CONVERSION_FAILED]: 'Conversion failed',
  
  // Journey events
  [TRACK_EVENTS.EXPAND_ALL_JOURNEY_TOGGLE_ON]: 'Expand_all_journey_toggle_on',
  [TRACK_EVENTS.EXPAND_ALL_JOURNEY_TOGGLE_OFF]: 'Expand_all_journey_toggle_off',
  [TRACK_EVENTS.SHOW_MORE_JOURNEY_MESSAGES]: 'Show_more_journey_messages',
  [TRACK_EVENTS.SHOW_MORE_JOURNEY_NOTES]: 'Show_more_journey_notes',
  
  // SR related events
  [TRACK_EVENTS.POPULATED_SR_CANCEL_CLICK]: 'Populated_sr_cancel_click',
  [TRACK_EVENTS.POPULATED_SR_CREATE_CLICK]: 'Populated_sr_create_click',
  [TRACK_EVENTS.NEW_SR_I_PREFER_MANUAL_LABOR_CLICK]: 'New_sr_i_prefer_manual_labor_click',
  [TRACK_EVENTS.NEW_SR_AUTO_FILL_FOR_ME_CLICK]: 'New_sr_auto_fill_for_me_click',
  [TRACK_EVENTS.WARNING_POPUP_ABORT_MISSION_CLICK]: 'Warning_popup_abort_mission_click',
  [TRACK_EVENTS.WARNING_POPUP_AUTO_FILL_FOR_ME_CLICK]: 'Warning_popup_auto_fill_for_me_click',
  [TRACK_EVENTS.CHANGE_SR_TYPE_TO_INCIDENT_FROM_NEW_TICKET]: 'Change_sr_type_to_incident_from_new_ticket',
  [TRACK_EVENTS.CHANGE_SR_TYPE_TO_REQUEST_FROM_NEW_TICKET]: 'Change_sr_type_to_request_from_new_ticket',
  [TRACK_EVENTS.CHANGE_SR_TYPE_TO_PROBLEM_FROM_NEW_TICKET]: 'Change_sr_type_to_problem_from_new_ticket',
  [TRACK_EVENTS.CHANGE_SR_TYPE_TO_CHANGE_FROM_NEW_TICKET]: 'Change_sr_type_to_change_from_new_ticket',
  [TRACK_EVENTS.CREATE_NEW_SR_CREATION]: 'Create_new_sr_creation',
  [TRACK_EVENTS.CANCEL_NEW_SR_CREATION]: 'Cancel_new_sr_creation',
  [TRACK_EVENTS.CHANGE_SR_TYPE_CHEVRON_CLICK_FROM_NEW_TICKET]: 'Change_sr_type_chevron_click_from_new_ticket',
  
  // Related items events
  [TRACK_EVENTS.FIRST_LINK_CLICK_IN_RELATED_ITEMS_EMPTY_STATE_TAB]: 'First_link_click_in_related_items_empty_state_tab',
  [TRACK_EVENTS.CHOOSING_ENTITY_TYPE_IN_RELATED_ITEMS_BOX]: 'Choosing_entity_type_in_related_items_box',
  [TRACK_EVENTS.CHOOSING_RELATIONSHIP_TYPE_IN_RELATED_ITEMS_BOX]: 'Choosing_relationship_type_in_related_items_box',
  [TRACK_EVENTS.LINK_NEW_RELATED_ITEM_CLICK]: 'Link_new_related_item_click',
  [TRACK_EVENTS.CANCEL_NEW_RELATED_ITEM_LINK_CLICK]: 'Cancel_new_related_item_link_click',
  [TRACK_EVENTS.UNLINK_EXISTING_RELATED_ITEM_ICON_CLICK]: 'Unlink_existing_related_item_icon_click',
  [TRACK_EVENTS.UNLINK_EXISTING_RELATED_ITEM_BUTTON_CLICK_IN_POPUP]: 'Unlink_existing_related_item_button_click_in_popup',
  [TRACK_EVENTS.CANCEL_UNLINKING_EXISTING_RELATED_ITEM_BUTTON_CLICK_IN_POPUP]: 'Cancel_unlinking_existing_related_item_button_click_in_popup',
  [TRACK_EVENTS.PARENT_INDICATOR_CLICK_IN_TICKET_PAGE]: 'Parent_indicator_click_in_ticket_page',
  [TRACK_EVENTS.CHILD_INDICATOR_CLICK_IN_TICKET_PAGE]: 'Child_indicator_click_in_ticket_page',
  
  // Archive events
  [TRACK_EVENTS.ARCHIVE_DONE_FROM_POPUP]: 'Archive_done_click_from_popup',
  [TRACK_EVENTS.ARCHIVE_CANCELLED_FROM_POPUP]: 'Archive_cancelled_click_from_popup',
};

export const DATADOG_TRACK_EVENTS = {
  // Spaces AI events
  [TRACK_EVENTS.SPACES_AI_REPHRASE]: 'spaces.ai.rephrase',
  [TRACK_EVENTS.SPACES_AI_TICKET_SUMMARY_AND_SENTIMENT_DISPLAYED]: 'spaces.ai.ticket_summary_and_sentiment.displayed',
  [TRACK_EVENTS.SPACES_AI_TICKET_SUMMARY_ERROR]: 'spaces.ai.ticket_summary.error',
  [TRACK_EVENTS.SPACES_AI_SUGGESTED_CATEGORY_CHECKED]: 'spaces.ai.suggested_category.checked',
  [TRACK_EVENTS.SPACES_AI_SUGGESTED_CATEGORY_SELECTED]: 'spaces.ai.suggested_category.selected',

  // Custom columns events
  [TRACK_EVENTS.CUSTOM_COLUMN_ADD_TO_TEMPLATE]: 'spaces.custom_column.add_to_template',
  [TRACK_EVENTS.CUSTOM_COLUMN_REMOVE_FROM_TEMPLATE]: 'spaces.custom_column.remove_from_template',
  [TRACK_EVENTS.CUSTOM_COLUMN_POPULATED]: 'spaces.custom_column.populated',

  // Activities events
  [TRACK_EVENTS.ACTIVITIES_CLICKED]: 'spaces.activities.clicked',
  [TRACK_EVENTS.ACTIVITY_SUBMITTED]: 'spaces.activity.submitted',
  [TRACK_EVENTS.ACTIVITY_DELETED]: 'spaces.activity.deleted',

  // Ticket actions
  [TRACK_EVENTS.TICKET_MISSING_REQUIRED_FIELDS]: 'spaces.ticket.missing_required_fields',
  [TRACK_EVENTS.SWITCH_TO_CLASSIC_FEEDBACK]: 'spaces.switch_to_classic.feedback',
  [TRACK_EVENTS.ADD_NEW_COLUMN]: 'spaces.column.add_new',
  [TRACK_EVENTS.DELETE_COLUMN]: 'spaces.column.delete',
  [TRACK_EVENTS.OPEN_SR_FROM_QUEUE]: 'spaces.ticket.open_from_queue',
  [TRACK_EVENTS.DELETE_TICKET_WAS_CLICKED]: 'spaces.ticket.delete_clicked',

  // Template actions
  [TRACK_EVENTS.SET_TEMPLATE_AS_DEFAULT_WAS_CLICKED]: 'spaces.template.set_as_default',
  [TRACK_EVENTS.DUPLICATE_TEMPLATE_WAS_CLICKED]: 'spaces.template.duplicate_clicked',
  [TRACK_EVENTS.DELETE_TEMPLATE_WAS_CLICKED]: 'spaces.template.delete_clicked',

  // UI interactions
  [TRACK_EVENTS.POPUP_LINK_CLICKED]: 'spaces.popup_link_clicked',
  [TRACK_EVENTS.POPUP_BUTTON_CLICKED]: 'spaces.popup_button_clicked',
  [TRACK_EVENTS.OPEN_CLASSIC_QUEUE_CLICK]: 'spaces.classic.open_queue',
  [TRACK_EVENTS.OPEN_IN_CLASSIC_FROM_QUEUE_CLICK]: 'spaces.classic.open_from_queue',
  [TRACK_EVENTS.OPEN_IN_CLASSIC_FROM_TICKET_CLICK]: 'spaces.classic.open_from_ticket',
  [TRACK_EVENTS.RESOURCE_CENTER_MENU_CLICK]: 'spaces.resource_center.menu_clicked',

  // Conversion events
  [TRACK_EVENTS.CONVERT_TO_REQUEST_QUEUE]: 'spaces.conversion.to_request.queue',
  [TRACK_EVENTS.CONVERT_TO_REQUEST_TICKET_PAGE]: 'spaces.conversion.to_request.ticket_page',
  [TRACK_EVENTS.CONVERT_TO_INCIDENT_QUEUE]: 'spaces.conversion.to_incident.queue',
  [TRACK_EVENTS.CONVERT_TO_INCIDENT_TICKET_PAGE]: 'spaces.conversion.to_incident.ticket_page',
  [TRACK_EVENTS.CONVERSION_SUCCEEDED]: 'spaces.conversion.succeeded',
  [TRACK_EVENTS.CONVERSION_FAILED]: 'spaces.conversion.failed',

  // Journey events
  [TRACK_EVENTS.EXPAND_ALL_JOURNEY_TOGGLE_ON]: 'spaces.journey.expand_all.on',
  [TRACK_EVENTS.EXPAND_ALL_JOURNEY_TOGGLE_OFF]: 'spaces.journey.expand_all.off',
  [TRACK_EVENTS.SHOW_MORE_JOURNEY_MESSAGES]: 'spaces.journey.show_more_messages',
  [TRACK_EVENTS.SHOW_MORE_JOURNEY_NOTES]: 'spaces.journey.show_more_notes',

  // SR related events
  [TRACK_EVENTS.POPULATED_SR_CANCEL_CLICK]: 'spaces.populated_sr.cancel_clicked',
  [TRACK_EVENTS.POPULATED_SR_CREATE_CLICK]: 'spaces.populated_sr.create_clicked',
  [TRACK_EVENTS.NEW_SR_I_PREFER_MANUAL_LABOR_CLICK]: 'spaces.new_sr.manual_labor_clicked',
  [TRACK_EVENTS.NEW_SR_AUTO_FILL_FOR_ME_CLICK]: 'spaces.new_sr.auto_fill_clicked',
  [TRACK_EVENTS.WARNING_POPUP_ABORT_MISSION_CLICK]: 'spaces.warning_popup.abort_mission_clicked',
  [TRACK_EVENTS.WARNING_POPUP_AUTO_FILL_FOR_ME_CLICK]: 'spaces.warning_popup.auto_fill_clicked',
  [TRACK_EVENTS.CHANGE_SR_TYPE_TO_INCIDENT_FROM_NEW_TICKET]: 'spaces.new_sr.change_type.to_incident',
  [TRACK_EVENTS.CHANGE_SR_TYPE_TO_REQUEST_FROM_NEW_TICKET]: 'spaces.new_sr.change_type.to_request',
  [TRACK_EVENTS.CHANGE_SR_TYPE_TO_PROBLEM_FROM_NEW_TICKET]: 'spaces.new_sr.change_type.to_problem',
  [TRACK_EVENTS.CHANGE_SR_TYPE_TO_CHANGE_FROM_NEW_TICKET]: 'spaces.new_sr.change_type.to_change',
  [TRACK_EVENTS.CREATE_NEW_SR_CREATION]: 'spaces.new_sr.creation_approve',
  [TRACK_EVENTS.CANCEL_NEW_SR_CREATION]: 'spaces.new_sr.creation_cancel',

  // Related items events
  [TRACK_EVENTS.FIRST_LINK_CLICK_IN_RELATED_ITEMS_EMPTY_STATE_TAB]: 'spaces.related_items.empty_state.first_link_clicked',
  [TRACK_EVENTS.CHOOSING_ENTITY_TYPE_IN_RELATED_ITEMS_BOX]: 'spaces.related_items.choose_entity_type',
  [TRACK_EVENTS.CHOOSING_RELATIONSHIP_TYPE_IN_RELATED_ITEMS_BOX]: 'spaces.related_items.choose_relationship_type',
  [TRACK_EVENTS.LINK_NEW_RELATED_ITEM_CLICK]: 'spaces.related_items.link_new.clicked',
  [TRACK_EVENTS.UNLINK_EXISTING_RELATED_ITEM_ICON_CLICK]: 'spaces.related_items.unlink_existing.icon_clicked',
  [TRACK_EVENTS.UNLINK_EXISTING_RELATED_ITEM_BUTTON_CLICK_IN_POPUP]: 'spaces.related_items.unlink_existing.popup_button_clicked',
  [TRACK_EVENTS.PARENT_INDICATOR_CLICK_IN_TICKET_PAGE]: 'spaces.ticket.parent_indicator_clicked',
  [TRACK_EVENTS.CHILD_INDICATOR_CLICK_IN_TICKET_PAGE]: 'spaces.ticket.child_indicator_clicked',

  // Archive events
  [TRACK_EVENTS.ARCHIVE_DONE_FROM_POPUP]: 'spaces.archive.popup.done_clicked',
  [TRACK_EVENTS.ARCHIVE_CANCELLED_FROM_POPUP]: 'spaces.archive.popup.cancelled_clicked',

  // Duplicate events
  [TRACK_EVENTS.DUPLICATE_ACTION_ITEM_CLICK]: 'Duplicate_click',
  [TRACK_EVENTS.DUPLICATE_DONE_FROM_POPUP]: 'Got_it_click_heads_up_popup',
  [TRACK_EVENTS.DUPLICATE_CANCELLED_FROM_POPUP]: 'Cancel_click_heads_up_popup',
  // Merge events
  [TRACK_EVENTS.MERGE_CLICK_IN_BULK_ACTION_BAR]: 'Merge_click_in_bulk_action_bar',
  [TRACK_EVENTS.MERGE_CLICK_IN_SPECIFICATIONS_OVERLAY]: 'Merge_click_in_specifications_overlay',
  [TRACK_EVENTS.CANCEL_CLICK_IN_SPECIFICATIONS_OVERLAY]: 'Cancel_click_in_specifications_overlay',
  [TRACK_EVENTS.MERGE_ATTACHMENTS_IN_SPECIFICATIONS_OVERLAY]: 'Merge_attachments_in_specifications_overlay',
  [TRACK_EVENTS.MERGE_MESSAGES_IN_SPECIFICATIONS_OVERLAY]: 'Merge_messages_in_specifications_overlay',
  [TRACK_EVENTS.MERGE_NOTES_IN_SPECIFICATIONS_OVERLAY]: 'Merge_notes_in_specifications_overlay',
  [TRACK_EVENTS.MERGE_ACTIVITIES_IN_SPECIFICATIONS_OVERLAY]: 'Merge_activities_in_specifications_overlay',
  [TRACK_EVENTS.GOT_IT_CLICK_IN_MERGE_CONFIRMATION_POPUP]: 'Got_it_click_in_merge_confirmation_popup',
  [TRACK_EVENTS.CANCEL_CLICK_IN_MERGE_CONFIRMATION_POPUP]: 'Cancel_click_in_merge_confirmation_popup',
  [TRACK_EVENTS.CHECK_IN_MERGE_CONFIRMATION_POPUP]: 'Check_in_merge_confirmation_popup',

};
